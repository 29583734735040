import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/storeList',
    name: 'storeList',
    component: () => import('@/views/storeMgt/storeList/index'),
    meta: {
      title: '供应商管理',
    },
  },
  {
    path: '/addStore',
    component: () => import('@/views/storeMgt/storeList/addStore'),
    meta: {
      title: '添加供应商',
    },
  },
  // 经营方管理
  {
    path: '/operateParty',
    name: 'operateParty',
    component: () => import('@/views/operateParty/index'),
    meta: {
      title: '经营方管理'
    }
  },


  /**
   * 
   */
  {
    path: '/updateStore',
    component: () => import('@/views/storeMgt/storeList/updateStore'),
    meta: {
      title: '供应商设置',
    },
  },
  {
    path: '/siteProduct',
    component: () => import('@/views/storeMgt/storeList/siteProduct'),
    meta: {
      title: '产品设置',
    },
  },
  {
    path: '/siteSample',
    component: () => import('@/views/storeMgt/storeList/siteSample'),
    meta: {
      title: '样片设置',
    },
  },
  {
    path: '/addEnterpriseApply',
    component: () => import('@/views/storeMgt/storeList/addEnterpriseApply'),
    meta: {
      title: '企业入网申请',
    },
  },
  {
    path: '/addPersonalApply',
    component: () => import('@/views/storeMgt/storeList/addPersonalApply'),
    meta: {
      title: '个人入网申请',
    },
  },
  {
    path: '/withdrawalSite',
    component: () => import('@/views/storeMgt/storeList/withdrawalSite'),
    meta: {
      title: '提现申请',
    },
  },
  {
    path: '/rules',
    component: () => import('@/views/storeMgt/rules'),
    meta: {
      title: '设置分账比列',
    },
  },
];

// console.log(process.env.BASE_URL);

const router = new VueRouter({
  mode: 'history',
  // 为微应用路由加上前缀，如果不加微应用路由切换会有问题
  // eslint-disable-next-line no-underscore-dangle
  base: window.__POWERED_BY_QIANKUN__ ? '/index/storefront' : process.env.BASE_URL,
  routes,
});

export default router;
