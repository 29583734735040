<template>
  <div class="upload-container">
    <div class="flex">
      <el-button slot="trigger" icon="el-icon-upload" size="mini" type="primary" @click="handleUploadFile" v-if="uploadFileType === 'button'">选取文件</el-button>
      <div class="img-sty" v-else-if="uploadFileType === 'img'" @click="handleUploadFile">
        <div class="img-sty-icon">+</div>
        <div>选取照片</div>
      </div>
      <u-list :list="fileList" v-if="fileList.length" />
    </div>
    <u-dialog :showUDialog="showUDialog" :title="title" :prompt="prompt" :uploadMultiple="uploadMultiple" :dList.sync="files" v-on:hidePopups="showUDialog = false" />
  </div>
</template>
<script>
import uDialog from './uDialog';
import uList from './uList';
export default {
  name: 'upload',
  components: {
    uDialog,
    uList,
  },
  props: {
    uploadFileType: {
      type: String,
      default: 'button',
    },
    uploadType: {
      type: String,
      default: '',
    },
    fileList: {
      type: Array,
      default: () => [],
    },
    uploadMultiple: {
      type: Number,
      default: 999999,
    },
    prompt: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
  },
  watch: {
    fileList: {
      immediate: true,
      deep: true,
      handler(data) {
        this.files = this.fileList;
      },
    },
    files: {
      deep: true,
      handler(data) {
        this.$emit('update:fileList', this.files);
      },
    },
  },
  data() {
    return {
      showUDialog: false,
      files: [],
    };
  },
  methods: {
    //上传文件弹窗
    handleUploadFile() {
      if (this.fileList.length >= this.uploadMultiple) {
        this.$notify({ title: '提示', message: '仅支持单文件上传', type: 'warning' });
        return false;
      }
      this.showUDialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.upload-container {
  display: inline-block;
  .img-sty {
    margin: 0 10px 0 0;
    min-width: 120px;
    height: 120px;
    background: #f5f5f5;
    border-radius: 4px;
    border: 1px solid #e0e0e0;
    text-align: center;
    color: #363f47;
    .img-sty-icon {
      margin-top: 20px;
      color: #9a9d9e;
      font-size: 28px;
    }
  }
  .img-sty:hover {
    cursor: pointer;
  }
}
</style>
