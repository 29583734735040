<template>
  <el-dialog :title="title + '上传'" append-to-body width="820px" top="10vh" :close-on-click-modal="false" :visible.sync="showUDialog" :before-close="handleClose">
    <div class="upload-content">
      <el-upload
        ref="upload"
        action="#"
        class="upload-file"
        :show-file-list="false"
        :multiple="uploadMultiple == 1 ? false : true"
        :limit="uploadMultiple"
        :file-list="files"
        :on-exceed="handFileExceed"
        :disabled="uploadMultiple == 1 && files.length == uploadMultiple ? true : false"
        :on-change="hanldleChange"
        :http-request="handleupload"
        drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          <span>将文件拖到此处，或</span>
          <em>点击选择文件</em>
        </div>
        <div class="el-upload__tip" slot="tip">{{ prompt }}</div>
      </el-upload>
    </div>
    <u-list :list="files" v-if="files.length" />

    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose" size="mini">取 消</el-button>
      <el-button type="primary" class="btn-theme-color" size="mini" @click="handleEnter" v-show="files.length > 0" :loading="uploadLoading">
        {{ uploadLoading ? '正在上传中...' : '上传' }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { upload } from '@/api/common';
import uList from './uList';
export default {
  name: 'uDialog',
  components: {
    uList,
  },
  props: {
    showUDialog: {
      type: Boolean,
      default: false,
    },
    dList: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    uploadMultiple: {
      type: Number,
      default: 999999,
    },
    prompt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      files: [], //上传文件列表
      uploadLoading: false,
    };
  },
  watch: {
    showUDialog(val) {
      if (val) {
      }
    },
  },
  methods: {
    //图片超出限制钩子
    handFileExceed(files, fileList) {},

    //上传前
    beforeUploadFile(file) {},
    hanldleChange(file, fileList) {
      this.files = fileList;
    },
    //确定
    async handleEnter() {
      try {
        this.uploadLoading = true;
        let data = [];
        for (let i = 0; i < this.files.length; i++) {
          // console.log(i);
          let item = this.files[i];
          let formData = new FormData();
          formData.append('file', item.raw);
          let fileData = (await upload(formData)).data;
          data.push(fileData);
          if (i === this.files.length - 1) {
            // console.log('完成');
            // console.log(this.dList);
            // console.log(data);
            this.uploadLoading = false;
            let allFiles = [...this.dList, ...data];
            this.$emit('update:dList', allFiles);
            this.$emit('hidePopups');
            this.files = [];
          }
        }
      } catch (err) {
        this.uploadLoading = false;
        this.$emit('update:dList', []);
      }
    },

    //取消（弹窗关闭）
    handleClose() {
      this.$emit('hidePopups');
      this.files = [];
    },
    //自定义上传
    async handleupload(file) {},
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-upload {
  display: block;
}
::v-deep.el-upload-dragger {
  width: 100%;
}
::v-deep .el-upload__tip {
  margin: 6px 0;
}
::v-deep .el-dialog__body {
  padding: 30px 20px 0 20px;
}
::v-deep .el-dialog__title {
  color: #1890ff;
  font-weight: 500;
  font-size: 16px;
}
::v-deep .el-dialog__header {
  border-bottom: 1px solid #eee;
}
</style>
