<template>
  <div class="l-table">
    <el-table
      v-loading="tableLoading"
      border
      :data="tableData"
      :row-style="rowStyle"
      @selection-change="mirChange"
      :max-height="tableHeight"
      @row-click="rowClick"
      @cell-click="cellClick"
      @sort-change="sortChange"
    >
      <template v-for="(item, i) in tableHeader">
        <el-table-column :key="i" type="selection" :selectable="item.selectable || null" width="60" align="center" v-if="item.label == 'selection'"></el-table-column>
        <!-- <el-table-column :key="i" type="index" :index="indexMethod" :label="'序号'" width="60" align="center" v-else-if="item.label == '序号'"></el-table-column> -->
        <el-table-column :key="i" type="expand" v-if="item.label == 'expand'">
          <template slot-scope="scope">
            <slot name="expand" :row="scope.row" :index="scope.$index"></slot>
          </template>
        </el-table-column>
        <el-table-column
          :key="i + 'u'"
          :label="item.label"
          :prop="item.prop"
          :width="item.width || ''"
          :min-width="item.minWidth || ''"
          :sortable="item.sortable || false"
          :fixed="item.fixed || false"
          :align="item.align || 'left'"
          show-overflow-tooltip
          v-else-if="item.render"
        >
          <template slot-scope="scope">
            <slot
              :name="item.prop"
              :row="scope.row" 
              :index="scope.$index"
              v-if="item.render"
            >
            </slot>
          </template>
        </el-table-column>
        <el-table-column
          :key="i + 'i'"
          :label="item.label"
          :prop="item.prop"
          :width="item.width || ''"
          :min-width="item.minWidth || ''"
          :sortable="item.sortable || false"
          :fixed="item.fixed || false"
          :align="item.align || 'left'"
          show-overflow-tooltip
          v-else-if="!item.render"
        ></el-table-column>
      </template>
    </el-table>
    <div class="flex table-operate-container">
      <el-pagination
        class="pagination"
        background
        @current-change="handlePagination"
        @size-change="handleSizeChange"
        :current-page="tableQueryData.pageNumber"
        :page-sizes="[10, 50, 100, 200]" 
        :page-size="tableQueryData.pageSize"
        :total="total"
        layout="total, prev, pager, next, sizes, jumper"
        v-if="isPagination"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'l-table',
  props: {
    tableLoading: {
      type: Boolean,
      defalut: false,
    },
    isPagination: {
      type: Boolean,
      defalut: false,
    },
    total: {
      type: Number,
      defalut: 0,
    },
    tableHeader: {
      type: Array,
      defalut: () => [],
    },
    tableQueryData: {
      type: Object,
      defalut: () => ({}),
    },
    tableData: {
      type: Array,
      defalut: () => [],
    },
    getTableList: {
      type: Function,
      defalut: null,
    },
    selectTableData: {
      type: Array,
      defalut: () => [],
    },
    rowKey: {
      type: String,
      default: '$index',
    },
    rowStyle: {
      type: Function,
      defalut: null,
    },
    treeProps: {
      type: Object,
      defalut: () => ({}),
    },
    expandRowKeys: {
      type: Array,
      default: () => [],
    },
    tableHeight: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      isSelectAll: undefined,
    };
  },
  created() {
    // this.$nextTick(() => {
    //   let searchFromHeight = 0;
    //   let batchOperatorHeight = 0;
    //   if (this.$slots.searchFrom) {
    //     searchFromHeight = this.$slots.searchFrom[0].componentInstance.$el.clientHeight;
    //   }
    //   if (this.$slots.batchOperator) {
    //     batchOperatorHeight = this.$slots.batchOperator[0].componentInstance.$el.clientHeight;
    //   }
    //   window.innerHeight - 134 - 172 < 500 ? (this.tableHeight =  500) : (this.tableHeight = window.innerHeight);
    // });
  },
  methods: {
    //计算序号
    indexMethod(index) {
      if (this.isPagination) {
        return index + 1 + (this.tableQueryData.pageNumber - 1) * this.tableQueryData.pageSize;
      } else {
        return index + 1;
      }
    },
    mirTableSelectAll(value) {
      console.log(value);
    },
    handlePagination(val) {
      this.tableQueryData.pageNumber = val;
      this.$emit('update:tableQueryData', this.tableQueryData);
      this.getTableList();
    },
    handleSizeChange(val) {
      this.tableQueryData.pageSize = val;
      this.$emit('update:tableQueryData', this.tableQueryData);
      this.getTableList();
    },
    mirChange(selection) {
      this.$emit('update:selectTableData', selection);
    },
    rowClick(row, column, event) {
      this.$emit('rowClick', row, column, event);
    },
    cellClick(row, column, event) {
      this.$emit('cellClick', row, column, event);
    },
    sortChange(e) {
      this.$emit('sortChange', e);
    },
  },
};
</script>
<style lang="scss" scoped>
$tableTheadColor: #000000;
$tableTheadSize: 14px;
$tableBorderColor: #e0e0e0;
.l-table {
  // ::v-deep .el-table-column--selection .cell {
  //   padding-right: 10px;
  // }
  // ::v-deep .el-table {
  //   color: $tableTheadColor;
  // }
  // ::v-deep .el-table thead {
  //   font-size: $tableTheadSize;
  //   font-weight: 400;
  //   color: $tableTheadColor;
  // }
  // ::v-deep .el-table th.el-table__cell {
  //   background-color: #f5f5f5;
  // }
  // ::v-deep .el-table--border:after,
  // ::v-deep .el-table--group:after,
  // ::v-deep .el-table:before {
  //   background-color: $tableBorderColor;
  // }
  // ::v-deep .el-table--border,
  // ::v-deep .el-table--group {
  //   border-color: $tableBorderColor;
  // }
  // ::v-deep .el-table td,
  // ::v-deep .el-table th.is-leaf {
  //   border-bottom: 1px solid $tableBorderColor;
  // }
  // ::v-deep .el-table--border th,
  // ::v-deep .el-table--border th.gutter:last-of-type {
  //   border-bottom: 1px solid $tableBorderColor;
  // }
  // ::v-deep .el-table--border td,
  // ::v-deep .el-table--border th {
  //   border-right: 1px solid $tableBorderColor;
  // }
  // ::v-deep .el-pagination__jump {
  //   margin-left: 0;
  // }
  .table-operate-container {
    padding: 30px 0;
    justify-content: flex-end;
  }
}
</style>
