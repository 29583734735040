<template>
  <div>
    <div class="upload-list" v-for="(item, i) in list" :key="i">
      <div class="img-list">
        <el-image style="width: 106px; height: 106px;  border-radius: 6px;" :src="verUrl(item)" :preview-src-list="[verUrl(item)]"></el-image>
        <img class="img-list-del" :src="require('@/static/fileDelete.png')" alt @click="remove(i)" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'uList',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    verUrl(url) {
      const keys = Object.keys(url);
      if (keys.some(item => item === 'raw')) {
        return window.URL.createObjectURL(url.raw);
      } else {
        return url.http;
      }
    },
    //移除图片触发
    remove(index) {
      this.$emit('update:list', this.list.splice(index, 1));
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-list {
  margin: 0 10px 0 0;
  display: inline-block;
  .img-list {
    position: relative;
    width: 106px;
    height: 106px;
    vertical-align: middle;
    // .img-list-img {
    //   width: 106px;
    //   height: 106px;
    //   border-radius: 6px;
    // }
  }
  .videos {
    position: relative;
    margin: 10px 0 0 0;
    width: 100%;
    height: 200px;
    border: 1px solid #eee;
  }
  .img-list-del {
    cursor: pointer;
    position: absolute;
    top: -0;
    right: 0;
    width: 18px !important;
    height: 18px !important;
  }
  .file_name {
    position: absolute;
    left: 0;
    bottom: 20px;
    width: 120px;
    padding: 0 10px;
    text-align: center;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
