import axios from 'axios';
import { Notification } from 'element-ui';
import { getToken, setToken } from '@/utils/auth';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 15000, // request timeout
});

service.interceptors.request.use(
  (config) => {
    let token = getToken();
    if (process.env.NODE_ENV === 'development') {
      token =
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EifQ==.piMAGXH/8EM4ajM/xEIHgF5aOvMPvMiHkK5UUDD9WRe5VN190MKWbGHTemllAcdMsqf3IzneQDjnq5bICu7NnWyr4pIGeTHOw9gTdLeusezQYnl52woQypKUxtDaNv41XvhlEiks6fOVEMb8geivwHuNJbmy2B7x7TkQBLB3xRD/OZ0RP5KdwDvht0uMlbqct40SpCvRJVN6uOrNt54SGoHWfS8o+hmaIE/kcauJUtgmRxPOCAq2ZrR+rbYh/T374ZhmTJuF9f590erjvyefguAVWWHFw1/aZIqpRZu9l6QbCruSydua43+X/7EhMRTQ.cbe05df4b7485c3588b93bb10cc387483e90d6e0';
      // 'eyJ0eXAiOiJKV1QiLCJhbGciOiJSU0EifQ==.piMAGXH/8EM4ajM/xEIHgNIvigrvpSTIJBWvB1ez/4biWJcJgMrp4/VI0N6C1f2Bv4I2yA5Gp5YSte8sattShWyr4pIGeTHOw9gTdLeusezQYnl52woQypKUxtDaNv41XvhlEiks6fOVEMb8geivwHuNJbmy2B7x7TkQBLB3xRAAdhtqltcsfxtNfF/59x3ESZ7x4mDsE7NAWmvMHJpFoeFerri+MyP75kWRg9G1JiwmRxPOCAq2ZrR+rbYh/T37zGyTnay4t73xuH4bRvx4gJzUkhJ1BlOT9jtT/aoMkgsfUTWKsHsvSnvhhJkEaAXO.df0a90433e2d866bdf0937024c8aa839a2a9c22e';
      setToken(token);
    }
    if (token) {
      config.headers['accessToken'] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

service.interceptors.response.use(
  (response) => {
    const data = response.data;
    if (data.success || data.size) {
      return data;
    } else if (data.code === '401') {
      Notification.error({
        title: '错误',
        message: data.description,
      });
      setTimeout(() => {
        window.location = data.redirectUrl;
      }, 500);
      return Promise.reject(data.message);
    } else {
      Notification.error({
        title: '错误',
        message: data.description,
      });
      return Promise.reject(data.message);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default service;
