import Vue from 'vue';
import 'normalize.css/normalize.css';
import LTable from '@/components/LTable';
import UploadFile from '@/components/UploadFile';
import '../theme/index.css';
import ElementUI from 'element-ui';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/styles/index.scss';
import './public-path';
import businesscomponent from 'business-component-vue2';
Vue.use(ElementUI, { size: 'small', zIndex: 3000 });
//引入表格组件

Vue.component('LTable', LTable);

//上传文件组件
Vue.component('UploadFile', UploadFile);
Vue.use(businesscomponent, { url: process.env.VUE_APP_BASE_API });
Vue.config.productionTip = false;

let instance = null;
function render(props = {}) {
  // container 是 qiankun 包装过的微应用容器
  const { container } = props;

  instance = new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app');
}

// 独立运行时
!window.__POWERED_BY_QIANKUN__ && render();

export async function bootstrap() {
  console.log('[vue] vue app bootstraped');
}

export async function mount(props) {
  render(props);
}

export async function unmount() {
  instance.$destroy();
  instance.$el.innerHTML = '';
  instance = null;
}
